module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reset Health","short_name":"Reset Health","start_url":"/","background_color":"#90f9de","theme_color":"#90f9de","display":"minimal-ui","icon":"src/images/favicons.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"43479f0cfafa16dc5d766a002fb61be0"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-TKHDCKD","cookieName":"gatsby-gdpr-google-tagmanager"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
